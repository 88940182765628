<!-- 考生成绩表 -->
<template>
    <div class="examineeResult">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
            <el-form-item label="考试日期">
                <el-date-picker v-model="form.testDate" type="daterange"
                range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="学科名称">
                <el-select v-model="form.subject" placeholder="请输入并选择学科" :clearable="true">
                    <el-option v-for="item in form.subjectOpt" :key="item.id" :label="item.course_name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择机构">
                <el-select v-model="form.mechanism" filterable placeholder="请输入并选择机构" :clearable="true">
                    <el-option v-for="item in form.mechanismOpt" :key="item.id" :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="考试大类">
                <el-select v-model="form.exam" filterable placeholder="请选择考试大类" :clearable="true">
                    <el-option v-for="item in form.examOpt" :key="item.id" :label="item.exam_name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
            </el-form-item>
            <el-form-item label="单据状态">
                <el-select v-model="form.condition" placeholder="请选择单据状态" :clearable="true">
                    <el-option v-for="item in form.conditionChoose" :key="item.id" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <!-- 按钮功能部分 -->
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" 
                    style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <button class="print" @click="print">打印预览</button>
                <button class="derive" @click="derive">导出到Excel</button>
                <button class="more" @click="more">更多</button>
            </div>
            <div class="" style="margin-right: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe @selection-change="checkAll"
         style="width:97.9%;flex: 1;" height="650" @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="status" label="单据状态" sortable="custom" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0||scope.row.status==''"></span>
                    <span v-else-if="scope.row.status==1">草稿</span>
                    <span v-else-if="scope.row.status==2">报考</span>
                    <span v-else-if="scope.row.status==3">准考</span>
                    <span v-else-if="scope.row.status==4">考试OK</span>
                    <span v-else-if="scope.row.status==5">取消</span>
                    <span v-else-if="scope.row.status==6">退款取消</span>
                    <span v-else-if="scope.row.status==7">延期</span>
                    <span v-else-if="scope.row.status==8">缺考</span>
                </template>
            </el-table-column>
            <el-table-column prop="is_receipt" label="机构收款" sortable="custom" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_receipt==0">未收款</span>
                    <span v-else-if="scope.row.is_receipt==1">已收款</span>
                </template>
            </el-table-column>
            <el-table-column prop="is_transfer" label="过账状态" sortable="custom" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_transfer==0">未过账</span>
                    <span v-else-if="scope.row.is_transfer==1">已过账</span>
                </template>
            </el-table-column>
            <el-table-column prop="is_deputy"  label="是否副证" sortable="custom" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_deputy==0">否</span>
                    <span v-else-if="scope.row.is_deputy==1">是</span>
                </template>
            </el-table-column>
            <el-table-column prop="enroll_number"  label="报名单号" sortable="custom" align="center"  width="180" >
            </el-table-column>
            <el-table-column prop="certificate_no"   label="准考证号" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="name"   label="学员姓名" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="idcard"  label="身份证" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="head_image"  label="相片" align="center" width="100">
                <template slot-scope="scope">
                     <img :src="scope.row.head_image" style="width:15px;height:20px;margin-top: 5px;">
                 </template>
            </el-table-column>
            <el-table-column prop="phone"  label="联系电话" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="org_name"  label="提供机构" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course"  label="考试科目" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="original_level"  label="原等级" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="exam_level"  label="等级" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="exam_date"  label="报名日期" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="apply"  label="申请考试大类" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="exam2_date"  label="考试日期" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_startdate"  label="开始时间" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_enddate"  label="结束时间" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="exam_duration"  label="时长(hr)" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="enroll_price"  label="报名费(元)" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="manage_price"  label="考级管理费(元)" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="certificatecopy_price"  label="证书费(元)" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="price1"  label="应收金额(元)" align="center" sortable="custom"  width="130">
            </el-table-column>
            <el-table-column prop="price2"  label="实收金额(元)" align="center" sortable="custom" width="130">
            </el-table-column>
            <el-table-column prop="difference"  label="差额(元)" align="center" sortable="custom" width="120">
            </el-table-column>
            <el-table-column prop="order_name"  label="下单人" align="center" sortable="custom" width="150">
            </el-table-column>
            <el-table-column prop="remark"  label="考试备注" align="center" sortable="custom" width="200"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="text_score"  label="成绩" align="center" sortable="custom" width="120">
            </el-table-column>
            <el-table-column prop="exam_result"  label="成绩等级" align="center" sortable="custom" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.exam_result==1">-</span>
                    <span v-else-if="scope.row.exam_result==2">
                        {{ scope.row.exam_level }}
                    </span>
                    <span v-else-if="scope.row.exam_result==3">
                        {{ scope.row.original_level }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="birthday" sortable label="出生日期" align="center" sortable width="120">
            </el-table-column>
            <el-table-column prop="age" sortable label="报考时年龄" align="center" sortable width="120">
            </el-table-column>
            <el-table-column prop="area_address" sortable label="场地" align="center" sortable width="200">
            </el-table-column>
            <el-table-column prop="add_name" sortable label="录入人" align="center" sortable width="150">
            </el-table-column>
            <el-table-column prop="add_time" sortable label="录入时间" align="center" sortable width="150">
            </el-table-column>
            <el-table-column prop="update_name" sortable sortable label="修改人" align="center" sortable width="150">
            </el-table-column>
            <el-table-column prop="update_time" sortable label="最近修改时间" align="center" sortable width="200">
            </el-table-column>
            <el-table-column  label="操作" align="center" width="120" fixed="right">
                <template slot-scope="scope">
                    <el-button class="details" icon="el-icon-view" size="mini" 
                    @click="detailed(scope.$index, scope.row)">
                        详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 底部 -->
        <div class="" style="display: flex;justify-content: space-between;align-items: center;">
            <div class="" style="margin-left: 22px;line-height: 25px;">
                <div class="txtbox">
                    报名人数：<input type="text" :value="peopleNum" class="itp"  disabled="disabled"/>
                </div>
                <div class="txtbox">
                    报名费合计：<input type="text" :value="amount" class="itp"  disabled="disabled"/>
                </div>
            </div>
            <div class="" style="margin-right:22px;">
                <!--分页 -->
                <el-pagination @size-change="handleSizeChange" 
                @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-sizes="[20,100,500,2000,5000,50000]" :page-size="10" background
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        
        <!-- 打印预览弹出框 -->
        <el-dialog width="860px" :visible.sync="dialog" >
            <div id="printTest">
                <!-- 头部 -->
                <div class="top">
                    <div class="top-logo">
                        <img :src="printData.top_logo">
                    </div>
                    <div class="top-title">
                        社会美术水平考级中心全国美术等级考试
                    </div>
                </div>
                <!-- 报名表 -->
                <div class="sign_up">
                    报名表
                </div>
                <!-- 编号 -->
                <div class="" style="text-align: right;">
                    <div class="" style="margin-right: 40px;font-size: 16px;">
                        No:<span style="padding-left: 10px;">{{ printData.number }}</span>
                    </div>
                </div>
                <!-- 表格 -->
                <table border="1" cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="td_1" rowspan="5"><img :src="printData.head_img"></td>
                        <td>所属地区：</td>
                        <td>{{ printData.region }}</td>
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <td>姓名</td>
                        <td>{{ printData.name }}</td>
                        <td class="td_2">性别</td>
                        <td class="td_3">{{ printData.sex }}</td>
                    </tr>
                    <tr>
                        <td>拼音名</td>
                        <td>{{ printData.pinyin }}</td>
                        <td class="td_2">国籍</td>
                        <td class="td_3">{{ printData.nationality }}</td>
                    </tr>
                    <tr>
                        <td>报考专业</td>
                        <td>{{ printData.major }}</td>
                        <td class="td_2">民族</td>
                        <td class="td_3">{{ printData.nation }}</td>
                    </tr>
                    <tr>
                        <td>原有级别</td>
                        <td>{{ printData.original_grade }}</td>
                        <td class="td_2">现报级别</td>
                        <td class="td_3">{{ printData.level }}</td>
                    </tr>
                    <tr>
                        <td>家庭住址</td>
                        <td colspan="2">{{ printData.address }}</td>
                        <td class="td_2">出生日期</td>
                        <td class="td_3">{{ printData.birthday }}</td>
                    </tr>
                    <tr>
                        <td>手机号码</td>
                        <td colspan="2">{{ printData.phone }}</td>
                        <td class="td_2">邮编</td>
                        <td class="td_3">{{ printData.Zipcode }}</td>
                    </tr>
                    <tr>
                        <td>考生证件号</td>
                        <td colspan="2">{{ printData.idcard }}</td>
                        <td class="td_2">电话</td>
                        <td class="td_3">{{ printData.telephone }}</td>
                    </tr>
                    <tr>
                        <td>备注</td>
                        <td colspan="4" class="remarks">
                            一、本表系录入全国社会美术考级数据库及考生等级证书的依据，请考生务必用正楷字如实填写。因填表错误而造成证书重做，考生必须负担证书重做的工本费。<br />
                            二、一经报名，不得退考、更改考试科目、报考级别。<br />
                            三、因考生本人原因缺考视为自动弃权，恕不退费和补考。
        
                        </td>
                    </tr>
                </table>
                <!-- 打印预览底部 -->
                <div class="bottom">
                    <!-- 考点经办人(签章) -->
                    <div class="handle">
                        考点经办人(签章)：<input type="text" />
                    </div>
                    <div class="bottom_text">
                        <p>中国美术学院社会美术水平考级中心</p>
                        <p>深圳书画艺术学院考级办公室</p>
                    </div>
                    <div class="phone_date">
                        <span>电话：0755-8883 0206</span>
                        <span>{{ printData.specificDate }}</span>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog=false">取 消</el-button>
                <el-button style="background: #18BC9C;color: #fff;" v-print="'#printTest'">打 印</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    testDate:"",//考试日期
                    subject:"",//学科名称
                    subjectOpt:[],//学科选项
                    mechanism:"",//选择机构
                    mechanismOpt:[],//机构名称选项
                    search:"",//搜索
                    exam:'',//考试大类
                    examOpt:[],//考试大类选项
                    region: "", //选择地区
                    condition: 0, //单据状态
                    conditionChoose: [{
                            value: 0,
                            label: "全部"
                        },
                        {
                            value: 1,
                            label: "草稿"
                        },
                        {
                            value: 2,
                            label: "报考"
                        },
                        {
                            value: 3,
                            label: "准考"
                        },
                        {
                            value: 4,
                            label: "考试OK"
                        },
                        {
                            value: 5,
                            label: "取消"
                        },
                        {
                            value: 6,
                            label: "退款取消"
                        },
                        {
                            value: 7,
                            label: "延期"
                        },
                        {
                            value: 8,
                            label: "缺考"
                        }
                    ], //单据状态选择
                },
                tableData:[],
                peopleNum:"",//报名人数
                amount:"",//报名费总计
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                ids: [],
                id:"",
                dialog: false, //判断打印预览开关
                printData: {
                    top_logo: require('../../assets/s-logo.png'), //logo
                    number: "", //编号
                    head_img: '', //头像
                    region: "", //所属地区
                    name: "", //姓名
                    sex: "", //性别
                    pinyin: "", //拼音名
                    nationality: "", //国籍
                    major: "", //报考专业
                    original_grade: "", //原有级别
                    level: "", //现报等级
                    address: "", //家庭住址
                    birthday: "", //出生日期
                    phone: "", //手机号码
                    idcard: "", //考试证件号
                    Zipcode: "", //邮编
                    telephone: "", //电话
                    remarks: "", //备注
                    specificDate: "2021年12月11日" //日期
                },
                arr:[]
            }
        },
        created() {
            // 学科数据
            this.$request({
                url: "/api/course/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res=>{
                if(res.code==1){
                    this.form.subjectOpt=res.data.list
                }
            })
            
            // 选择机构
            this.$request({
                url: "/api/mechanism/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log("选择机构",res.data.list)
                this.form.mechanismOpt = res.data.list
            })
            //考试大类
            this.$request({
                url: "/api/ExamCategory/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 1000
                }
            }).then(res => {
                // console.log("考试大类",res.data.list)
                this.form.examOpt = res.data.list
            })
            this.getList()
        },
        activated() {
           this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url: '/api/examenroll/list',
                    method: 'POST',
                    data: {
                        apply_category:this.form.exam,//考试大类id
                        page: this.currentPage,
                        limit: this.currentLimit,
                        dateQuery: this.form.testDate, //查询时间
                        search: this.form.search, //关键字
                        course_id: this.form.subject, //学科ID
                        org_id: this.form.mechanism ,//机构id
                        sort:this.arr,
                        status:this.form.condition
                    }
                }).then(res=>{
                    console.log(res)
                    if(res.code==1){
                        this.tableData=res.data.list
                        this.total=res.data.count
                        this.peopleNum=res.data.count
                        this.amount=res.data.sum_price
                    }
                })
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            more(){//更多
                this.$router.push({
                    path: "resultMore"
                })
            },
            detailed(index,row){//详情
                this.$router.push({
                    path: "resultDetails",
                    query:{
                        id:row.id
                    }
                })
            },
            query() { //查询
                this.currentPage = 1
                this.getList()
            },
            Reset() { //重置
                this.form.testDate = '' //查询时间
                this.form.subject = '' //学科名称id
                this.form.search = '' // 搜索
                this.form.mechanism = '' //机构id
                this.exam=''
                this.form.exam=''//考试大类
                this.form.condition=0
                this.getList()
            },
            checkAll(selection) { //获取批量收款id
                console.log(selection)
                this.ids = []
                this.id=''
                selection.forEach(item => {
                    this.ids.push(item.id)
                    this.id=item.id
                })
                console.log(this.ids)
            },
            derive() { //导出
                location.href =
                    `https://artxt.szart.cn/api/public/index.php/api/examenroll/export?
                    page=${this.currentPage}&limit=${this.currentLimit}&dateQuery=${this.form.testDate}
                    &search=${this.form.search}&course_id=${this.form.subject}&org_id=${this.form.mechanism}&status=${this.form.condition}`
            },
            print() {
                // console.log('打印预览')
                this.dialog = true
                this.$request({
                    url:'/api/examenroll/print',
                    method:"POST",
                    data:{
                       id:this.id 
                    }
                }).then(res=>{
                    console.log(res)
                    if(res.code==1){
                        this.$message({
                          message: res.msg,
                          type: 'success'
                        })
                        let data = res.data
                        let printData = this.printData
                        printData.number= data.enroll_number//编号
                        printData.head_img =data.student.head_image//头像
                        printData.region=data.area//所属地区
                        printData.name= data.name//姓名
                        printData.sex =data.student.gender//性别
                        printData.pinyin=data.english_name //拼音名
                        printData.nationality=data.student.nation2 //国籍
                        printData.nation=data.student.nation//民族
                        printData.major=data.course//报考专业
                        printData.original_grade= data.original_level//原有级别
                        printData.level =data.exam_level//现报等级
                        printData.address =data.student.address//家庭住址
                        printData.birthday= data.student.birthdate//出生日期
                        printData.phone= data.student.mobile1//手机号码
                        printData.idcard =data.idcard//考试证件号
                        printData.Zipcode= data.student.post_code//邮编
                        // printData.telephone= data.//电话
                        printData.specificDate=data.print_date//日期
                        
                    }else{
                        this.$message({
                          message: res.msg,
                          type: 'error'
                        })
                    }
                })
            },
            
            // 姓名排序
            fn2(a, b){
                return a.name.localeCompare(b.name)
            },
            
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .examineeResult{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 30px;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 打印预览 */
    .print{
        display: inline-block;
        padding-left: 10px;
        width: 94px;
        height: 30px;
        border-radius: 3px;
        vertical-align: middle;
        background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
        border: none;
        color: #FFFFFF;
        margin:0px 10px;
        cursor: pointer;
    }
    /* 导出按钮 */
    .derive{
         display: inline-block;
         width: 113px;
         height: 30px;
         padding-left: 10px;
         vertical-align: middle;
         background: url(../../assets/export.png) 6px 6px no-repeat #FF7300;
         color: #FFFFFF;
         border-radius: 3px;
         border: none;
         margin-right: 10px;
         cursor: pointer;
    }
    /* 更多 */
    .more{
        display: inline-block;
        width: 66px;
        height: 30px;
        vertical-align: middle;
        background: url(../../assets/more.png) 6px 8px no-repeat #29726C;
        color: #FFFFFF;
        border-radius: 3px;
        padding-left: 10px;
        border: none;
        cursor: pointer;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 28px;
    }
    .details{
       background: #18BC9C; 
       color: #fff;
    }
    /* 显示值 */
    .txtbox{
        height: 19px;
        display: inline-block;
        margin-bottom: 18px;
        margin-right: 5px;
        font-size: 14px;
    }
    .itp{
        border: 1px solid #ccc;
        height: 20px;
        padding-left: 5px;
        outline: none;
        font-size: 14px;
        background-color: #FFFFFF;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top:0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
    
    /* 打印弹出样式 */
    .examineeResult ::v-deep .el-dialog__body {
        padding: 30px 20px;
    }
    
    .examineeResult ::v-deep .el-dialog__footer {
        padding: 10px 20px 20px !important;
        text-align: center;
    }
    
    #printTest {
        width: 800px;
        /* height: 500px; */
        /* border: 1px #ccc solid; */
        margin: 0px auto;
        color: #333;
    }
    
    .top {
        width: 100%;
        height: 80px;
        /* border: 1px solid red; */
        display: flex;
        justify-content: space-between;
    }
    
    .top-logo {
        width: 350px;
        height: 100%;
    }
    
    .top-logo img {
        width: 100%;
        height: 100%;
    }
    
    .top-title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        letter-spacing: 5px;
    }
    
    .sign_up {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        color: #333;
        letter-spacing: 5px;
    }
    
    /* 打印预览表格 */
    table {
        margin-top: 10px;
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        font-weight: bold;
    }
    
    table td {
        height: 30px;
    }
    
    table td:first-child {
        width: 15%;
    }
    
    /* 相片 */
    .td_1 img {
        width: 100px;
        height: 130px;
        /* border: 1px dashed #ccc; */
        margin: auto;
    }
    
    .td_2 {
        width: 85px;
    }
    
    .td_3 {
        width: 130px;
    }
    
    table tr:last-child {
        height: 100px;
    }
    
    .remarks {
        font-size: 14px;
        font-weight: 500;
        font-family: Microsoft YaHei;
        color: #000000;
        text-align: left;
        padding-left: 10px;
    }
    
    /* 底部 */
    .bottom {
        text-align: right;
    }
    
    .handle {
        margin: 20px 0px;
        font-size: 17px;
        font-weight: bold;
        color: #000000;
    }
    
    .handle input {
        border: none;
    }
    
    .bottom_text p {
        font-size: 15px;
        color: #000000;
        padding-right: 10px;
    }
    
    .phone_date {
        margin-top: 10px;
        color: #000000;
    }
    
    .phone_date span {
        display: inline-block;
    }
    
    .phone_date span:first-child {
        padding-right: 20px;
    }
    
    .phone_date span:last-child {
        padding: 0px 10px;
    }
</style>